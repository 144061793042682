import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [

  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'home',
    name: 'dashbaord',
    url: 'dashboard'
  },
  // {
  //   id: 'users',
  //   title: 'Users',
  //   type: 'item',
  //   icon: 'users',
  //   name: 'users',
  //   url: 'users'
  // },
  {
    id: 'transactions',
    title: 'Transactions',
    type: 'item',
    fontAwesomeIcon: ' fa fa-exchange',
    name: 'transactions',
    url: 'transactions',

  },

  {
    id: 'faq',
    title: 'FAQ',
    type: 'item',
    fontAwesomeIcon: ' fa fa-question-circle',
    name: 'faq',
    url: 'faq',
  },

  {
    id: 'referral',
    title: 'Referral',
    type: 'item',
    fontAwesomeIcon: ' fa fa-question-circle',
    name: 'referral',
    url: 'referral',
  },

  {
    id: 'setting',
    title: 'Settings',
    type: 'item',
    fontAwesomeIcon: ' fa fa-cog',
    name: 'setting',
    url: 'setting'
  },

  {
    id: 'downloads',
    title: 'Downloads',
    type: 'item',
    fontAwesomeIcon: ' fa fa-download',
    name: 'downloads',
    url: 'downloads',
    role: ['Admin']
  },

];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptionService } from 'app/utils/encryption/encryption.service';
import { environment } from 'environments/environment';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/auth/models';
import { AuthService } from 'app/auth/auth.service';
import { AuthenticationService, UserService } from 'app/auth/service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;
  currentUser: User

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
    private auth: EncryptionService,
    private authService: AuthenticationService) {
    this.onApiDataChange = new BehaviorSubject('');
    this.currentUser = this.authService.currentUserValue;
    let enc = this.auth.encrypt(JSON.stringify({ userID: this.currentUser._id }))
    this.getNotificationsData(enc);
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(id): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}getNotification/${id}`).subscribe((response: any) => {
        this.apiData = response;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  getNotification(id): any {
    return this._httpClient.get(`${environment.apiUrl}getNotification/${id}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));

  }
}

<!-- Footer -->
<div class="clearfix mb-0 div-opp">
  <p class="float-md-left d-block d-md-inline-block mt-25">
    <!-- PROHAB AXARQUIA 202 SL - Calle Periodista Leovigildo Caballero Gutiérrez, 2, OFICINA 1 A, 29016 Málaga, Espanha -->
    COPYRIGHT © 2023-2024 <span>SPLITLEVEL</span>
  </p>
  <p class="float-md-right d-none d-md-block">
    <!-- NIF: B02744423 , Registration Data: Inscrito en la hoja MA-157857, tomo 5981, folio 125 -->
    <span>{{'downloads.footerText' | translate}}
       <!-- <img src="assets/images/footer-heart.png"> -->
    </span>
    <a class="social___a" href="https://www.facebook.com/sharer/sharer.php?u=#{{affilation_url}}" target="_blank"><img
      src="assets/images/pop-social.png" alt=""></a>
  <a class="social___a" href="http://www.twitter.com/share?url={{affilation_url}}" target="_blank"><img src="assets/images/pop-social-1.png" alt=""></a>
  <a class="social___a" href="https://www.linkedin.com/share?url={{affilation_url}}" target="_blank"><img src="assets/images/pop-social-2.png" alt=""></a>
  </p>
</div>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<!-- <div class="buy-now" *ngIf="currentUser && currentUser._id && currentUser.token">
  <a (click)="openDepositModal()" href="javascript:void(0)"  class="btn btn-danger">{{ 'DEPOSIT.depoNow' | translate}}</a>
</div> -->

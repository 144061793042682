import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { EncryptionService } from 'app/utils/encryption/encryption.service';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators'


@Injectable({
  providedIn: 'root'
})
export class MenusService {
  availableMenuNames: any[] = [];
  allMenus: any[] = [];
  currentUser: User;
  currentMenusSubjet: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);


  constructor(private _httpClient: HttpClient,
    private _encryptionService: EncryptionService,
    private _authService: AuthenticationService) {
    this.loadAllMenus();
    this._authService.currentUser.subscribe(
      x => {
        this.currentUser = x;
        this.mapMenus();
      }
    )
  }

  mapMenus() {

    this.availableMenuNames = [];
    if (this.currentUser && (this.currentUser.isAdmin == '2' || this.currentUser.menu_id) && this.allMenus && this.allMenus.length) {
      this.allMenus.forEach(
        item => {
          if (this.currentUser.isAdmin == '2' || this.currentUser.menu_id.includes(item.id))
            this.availableMenuNames.push(item.name);
        }
      );
    }
    this.availableMenuNames.push('Dashboard');
    this.availableMenuNames.push('Profile');
    if (this.currentUser && this.currentUser.isAdmin == '2') {
      this.availableMenuNames.push('Menus');
    }
    this.currentMenusSubjet.next(this.availableMenuNames);

  }

  loadAllMenus() {
    if (!this.allMenus || !this.allMenus.length) {
      this.getAllMenu().subscribe(
        res => {
          if (!res.error) {
            let data = res;
            this.allMenus = data.body;
            this.mapMenus();
          }
        }
      );
    }
  }

  addMenu(data, id = null): Observable<any> {
    // {name}
    let term = (id) ? 'updateMenu' : 'addMenu';
    return this._httpClient.post(`${environment.baseApiUrl}${term}`, data);
  }

  updateMenu(data): Observable<any> {
    // {id,name}
    return this._httpClient.post(`${environment.baseApiUrl}updateMenu`, data);
  }

  deleteMenu(data): Observable<any> {
    // {id}
    return this._httpClient.post(`${environment.baseApiUrl}deleteMenu`, data);
  }

  getAllMenu(): Observable<any> {
    return this._httpClient.get(`${environment.baseApiUrl}getAllMenu`).pipe(map(data => {
      return this._encryptionService.getDecode(data);
    }));

  }
  getUserMenus(user_id): Observable<any> {
    let enc = this._encryptionService.encode(JSON.stringify({ user_id: user_id }));
    return this._httpClient.get(`${environment.baseApiUrl}getUserMenus/${enc}`)
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);

        })
      );
  }
  addUserToMenu(data, id = null): Observable<any> {
    // {user_id,menus:[id of menu]}
    let enc = this._encryptionService.encode(JSON.stringify(data));
    let trm = (id) ? 'updatedUserMenu' : 'addUserToMenu';
    return this._httpClient.post(`${environment.baseApiUrl}${trm}`, { enc });
  }


}

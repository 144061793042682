import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { AuthenticationService } from 'app/auth/service';
import { LabelType } from 'ng5-slider';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  showlangDrop!: boolean;
  selectedValue!: string;
  subscriptionAll: any = [];
  langFLag!: string;

  constructor(
    public auth: AuthService
  ) {
    let val = this.auth.getLang() ? this.auth.getLang() : 'en';
    this.auth.changeLange(val);
    this.subscriptionAll.push(this.auth.langChange.subscribe((res) => {
      this.selectedValue = res;
      this.langFLag = this.selectedValue == 'en' ? 'en' : this.selectedValue == 'es' ? 'es' : this.selectedValue == 'fr' ? 'fr' : 'bz'
    }))
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptionAll.map(
      (item: any) => {
        item.unsubscribe();
        item = null;
      }
    )
  }

  get getLanguageLabel() {
    let label = '';
    switch(this.selectedValue) {
      case 'en':
        label = 'English';
        break;
      case 'es':
        label = 'Spanish';
        break;
      case 'fr':
        label = 'French';
        break;
      case 'pt':
        label = 'Portuguese';
        break; 
    }
    return label;
  }

}

import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { CoreConfigService } from '@core/services/config.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { AuthenticationService, UserService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { EncryptionService } from 'app/utils/encryption/encryption.service';
import { NetworkService } from 'app/auth/service/network.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { copyTextAreaToClipBoard } from 'app/auth/helpers';
declare var $: any;

@Component({
  selector: 'vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  isCollapsed: boolean;
  isScrolled: boolean = false;
  public mainlogo = '';
  currentUser: User
  userReferralForm: UntypedFormGroup;
  submitted: boolean = false;
  user: User
  public subsAll: any[] = [];
  affilation_url: string = "";
  isBtnDisabled: boolean;
  // Private
  private _unsubscribeAll: Subject<any>;
  @ViewChild('referralModal') referralModal: ElementRef<any>;
  showNotifications: boolean = true;
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {Router} _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _router: Router,
    private authService: AuthenticationService,
    private encryptionService: EncryptionService,
    private networkService: NetworkService,
    private userService: UserService,
    private translate: TranslateService,
    private toastr: ToastrManager,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,

  ) {
    this.mainlogo = this._coreConfigService.mainLogo;
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.authService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUser = this.authService.currentUserValue;


    this.userReferralForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });

    this.subsAll.push(this.userService.openReferralModal.subscribe((val) => {
      if (val) {
        this.modalOpenReferral(this.referralModal);
        this.userService.openReferralModal.next(null)
      }
    }))


  }

  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    this.isCollapsed = this._coreSidebarService.getSidebarRegistry('menu').collapsed;

    // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._coreSidebarService.getSidebarRegistry('menu')) {
          this._coreSidebarService.getSidebarRegistry('menu').close();
        }
      });

    // scroll to active on navigation end
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.directiveRef.scrollToElement('.navigation .active', -180, 500);
        });
      });

    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
      });

    if (this.currentUser?._id)
      this.getUserBalance();

    if (this.userService.isLogined()) {
      this.affilation_url = `${location.origin}/register/${this.currentUser.referral_code}`;
    }
  }


  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.subsAll.map(
      item => {
        item.unsubscribe();
        item = null;
      }
    )
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Sidebar scroll set isScrolled as true
   */
  onSidebarScroll(): void {
    if (this.directiveRef.position(true).y > 3) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  /**
   * Toggle sidebar expanded status
   */
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('menu').toggleOpen();
  }

  /**
   * Toggle sidebar collapsed status
   */
  toggleSidebarCollapsible(): void {
    // Get the current menu state
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.isCollapsed = config.layout.menu.collapsed;
      });

    if (this.isCollapsed) {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: false } } }, { emitEvent: true });
    } else {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: true } } }, { emitEvent: true });
    }
  }


  getUserBalance() {
    let input_data = { "userID": this.currentUser._id, "exchange_type": "" };
    let enc_data = this.encryptionService.encrypt(JSON.stringify(input_data))
    this.userService.getUserBalance({ enc: enc_data }).subscribe(response => {
      if (!response['error']) {
        this.networkService.updateUserBalance.next(response['body']);
      }
    }, error => {
      this.translate.get('commonErr.unble2gt').subscribe(res => {
        this.toastr.errorToastr(res);
      })
    });
  }


  modalOpenReferral(modalOSE, size = 'lg') {
    this.modalService.open(modalOSE,
      {
        backdrop: false,
        size: size,
        centered: true,
        windowClass: 'custom-referral'
      }
    );
  }

  get refForm() {
    return this.userReferralForm.controls;
  }

  shareReferral(modal) {
    this.submitted = true;
    if (this.userReferralForm.invalid) {
      return
    }

    let referralBody = {
      toEmail: this.userReferralForm.value.email,
      user_id: this.currentUser._id,
     // referral_url: this.affilation_url,
      referral_url: this.currentUser.referral_code

    }
    this.isBtnDisabled = true;
    this.userService.sendReferralLink(referralBody).subscribe(response => {
      console.log(response)
      if (!response['error']) {
        this.toastr.successToastr(response['msg']);
        this.closedReferalPopup(modal);
      } else {
        this.toastr.errorToastr(response['msg'])
      }
      this.isBtnDisabled = false;

    }, error => {
      this.isBtnDisabled = false;
      this.toastr.errorToastr(error);
    });
  }

  closedReferalPopup(modal: NgbModalRef) {
    modal.dismiss();
    this.isBtnDisabled = false;
    this.submitted = false;
    this.userReferralForm.reset();

  }

  copyInputMessage() {
    if (this.currentUser.referral_code) {
      const currentUrl = location.origin
      copyTextAreaToClipBoard(currentUrl+`/register/${this.currentUser.referral_code}`);
      this.translate.get('rfrlLnk').subscribe(res => {
        this.toastr.successToastr(res);
      })
    }
  }

  toggleClass() {
    this.showNotifications = !this.showNotifications

  }


}

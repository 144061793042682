export const environment = {
  production: false,
  hmr: false,
  //  Local URL
  // apiUrl: 'http://43.205.48.160:3044/',
  // baseApiUrl: 'http://43.205.48.160:3044/',
  
  //  Dev URL
  apiUrl: 'https://devapi.splitlevel.co/',
  baseApiUrl: 'https://devapi.splitlevel.co/',

  stripeKey: 'pk_test_51HcjDfIDD2jQaejEtqO4uSXeOgOSNZp4v4u5HmeOZzO2D2bg7wAzkv6xcFVAOSxCcnT2ApoIWUUK16yMEBxqYSDl00HwAcsrse',

  key_as: 'NTIwQDUyMUA1MjJANTIzQDUyNEA1MjVANTI2QDUyN0A1MjhANTI5QDUzMEA1MzFANTMyQDUzM0A1MzRANTM1QDUzNkA1MzdANTM4QDUzOUA1NDBANTQxQDU0MkA1NDNANTQ0QDU0NUA1NDZANTQ3QDU0OEA1NDlANTUwQDU1MQ==',
  key_vec: 'NTFANTJANTNANTRANTVANTZANTdANThANTlANjBANjFANjJANjNANjRANjVANjY=',
};

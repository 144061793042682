<div class="div-lang">
    <div class="dropdown">
        <button class="dropdown-toggle get_top_button" (click)="showlangDrop = !showlangDrop" type="button" id="dropdownMenuButton">
            <img class="same-language" src="assets/images/flag-{{langFLag}}.png"><span class="get_top">{{getLanguageLabel}}</span>
            <!-- <i class="fa fa-caret-down" aria-hidden="true"></i> -->
        </button>
        <div class="dropdown-menu" [class.show]="showlangDrop" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:void(0);" *ngIf="selectedValue != 'en'"
                (click)="auth.changeLange('en');showlangDrop = !showlangDrop">
                
                <img class="same-language" src="assets/images/flag-en.png">English
                <!--en-->
            </a>
            <a class="dropdown-item" href="javascript:void(0);" *ngIf="selectedValue != 'es'"
                (click)="auth.changeLange('es');showlangDrop = !showlangDrop">
                
                <img class="same-language" src="assets/images/flag-es.png">Spanish
                <!--es-->
            </a>
            <a class="dropdown-item" href="javascript:void(0);" *ngIf="selectedValue != 'pt'"
                (click)="auth.changeLange('pt');showlangDrop = !showlangDrop">
                
                <img class="same-language" src="assets/images/flag-bz.png">Portuguese
                <!--pt-->
            </a>
            <a class="dropdown-item" href="javascript:void(0);" *ngIf="selectedValue != 'fr'"
                (click)="auth.changeLange('fr');showlangDrop = !showlangDrop">
                
                <img class="same-language" src="assets/images/flag-fr.png">French
                <!--fr-->
            </a>
        </div>
    </div>
</div>
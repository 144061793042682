import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'app/auth/models';

@Pipe({
  name: 'username'
})
export class GetUsernamePipe implements PipeTransform {
  transform(value: User): any {
    return (!value) ? '' 
            : (value.first_name || value.last_name) 
            ? `${value.first_name} ${value.last_name}`
            : value.username;
  }
}
